<template>
	<div class="Authority">
		<div class="content">
			<h1>{{true_name}}分享的项目文件</h1>
			<el-table :data="this.tableData" style="width: 100%">
				<el-table-column prop="file_name" label="文件名称"> </el-table-column>
				<el-table-column prop="update_time" label="最近上传更新时间"> 
				<template slot-scope="scope">
					{{scope.row.update_time}}
				</template>
				</el-table-column>
				<el-table-column prop="operation" label="操作" width="250px">
					<template slot-scope="scope">
						<el-upload class="upload-demo" ref="imgupload" action="string" :name="''+scope.row.id" :http-request="Uploadfile" :show-file-list="false" :before-upload="beforeAvatarUpload" :file-list="fileList">
							<el-button type="primary" class="fileUpload">上传</el-button>
						</el-upload>
						<el-link v-if="scope.row.url" @click.native="() => nodeDownload(scope.row.order_file_id)"><el-button class="edit" size="mini">下载</el-button></el-link>

					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import site from '../../components/global.vue'
	export default {
		data() {
			return {
				userSite:site.userSite,
				tableData: [],
				fileList: [],
			};
		},
		mounted() {
			this.axios.get("/api/order/get_share_file", {
				params: {
					order_id: this.$route.query.order_id,
					code:this.$route.query.code
				},
				
			}).then((res) => {
				console.log(res)
				this.true_name=res.data[0].true_name
				this.tableData=res.data
			});
		},
		methods: {
			Uploadfile(param) {
				let file = param.file
				console.log(param)
				let cindex = param.filename
				const form = new FormData()
				form.append('file', file)
				form.append('order_file_id', cindex)
				this.upload('/api/order/upload_file', form).then((res) => {
				})
			},
			upload(url, params) {
				return axios.post(url, params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then((res) => {
					return new Promise((resolve, reject) => {
						resolve(res)
					})
				}).catch((err) => {
					console.log('err:', err);
				})
			},
			nodeDownload(order_file_id) {
				console.log(order_file_id)
				this.axios.get("/api/order/download", {
					params: {
						order_file_id:order_file_id,
					}
				}).then((res) => {
					window.open(this.userSite + res.data.url, '_blank');
				})
			
			},
			// 上传文件前的过滤
			beforeAvatarUpload(file) {
				const isLt20M = (file.size / 1024 / 1024) < 20
				if (!isLt20M) {
					this.$message.error('上传文件大小不能超过 20MB!')
				}
				return isLt20M
			},
		
		},
	};
</script>

<style scoped="scoped">
	.upload-demo{
		display: inline-block;
	}
.fileUpload{
		background: #F2F3F9 !important;
		    border-radius: 2px;
		    color: #0F38FF !important;
		    border: none;
		    font-weight: bold;
			padding: 7px 15px;
			    font-size: 12px;
				margin-right: 20px;
				
	}

</style>
